<template>
  <ZTooltip
    :title="title"
    :content="content"
  >
    <Fa
      :icon="icon"
      :class="['ztooltip-icon', computedClasses]"
    />
    <template #content>
      <slot name="content" />
    </template>
  </ZTooltip>
</template>

<script setup lang="ts">
import type { TooltipIconSize, TooltipIconVariant } from './types'

const props = withDefaults(defineProps<{
  content?: string
  title: string
  icon?: [string, string]
  iconVariant?: TooltipIconVariant
  iconSize?: TooltipIconSize
}>(), {
  icon: () => ['far', 'circle-question'],
  iconVariant: 'highlight',
  iconSize: 'md',
})

const computedClasses = computed(() => ({
  [`ztooltip-icon-${props.iconVariant}`]: !!props.iconVariant,
  [`ztooltip-icon-${props.iconSize}`]: !!props.iconSize,
}))
</script>
